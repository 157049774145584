import React, { useState, useMemo } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import { Card, Container, Form, Button, Row, Col } from "react-bootstrap";
import parse from "html-react-parser";
import filterIcon from "../images/filter-white.svg";
import resetIcon from "../images/filter-white.svg";
import { FaSearch } from "react-icons/fa";
import { MdOutlineReplay } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import NewsletterBanner from "../components/newsletter-banner";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";

const InsuranceCatPage = () => {
	const data = useStaticQuery(graphql`
		query {
			allWpInsuranceCategory(sort: { fields: dateGmt, order: DESC }) {
				nodes {
					dateGmt
					id
					title
				}
			}
			wpInsuranceCategoriesPage {
				seoFields {
					metaTitle
					opengraphTitle
					opengraphDescription
					metaDescription
					image {
						altText
						link
						localFile {
							childImageSharp {
								original {
									height
									width
								}
							}
						}
					}
				}
				insuranceCategoriesPageFields {
					heroSection {
						title
						description
					}
					ctaBannerSection {
						title
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
			allWpInsurancePage {
				nodes {
					title
					slug
					insurancePageFields {
						insuranceType {
							... on WpInsuranceCategory {
								id
								title
							}
						}
						cardDescription
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const linkGradientStyle = {
		background: "linear-gradient(138deg, #29B9F2 2.82%, #0433BF 97.42%)",
		WebkitBackgroundClip: "text",
		WebkitTextFillColor: "transparent",
		display: "inline", // Apply the gradient to text
		textDecoration: "none", // Optional: removes underline from links
		fontWeight: "bold", // Optional: for better visibility
	};
	const cardStyle = {
		padding: "1px", // This creates space for the gradient border
		borderRadius: "13px",
		background: "linear-gradient(138deg, #29B9F2 2.82%, #0433BF 97.42%)", // Gradient border
	};

	const contentStyle = {
		borderRadius: "12px",
		maxWidth: "100%",
		background: "white", // Content background (can be 'white' or any color)
		minHeight: "100%",
		// Or 'p-4' if using Bootstrap classes
	};

	const [categoryFilter, setCategoryFilter] = useState("All");
	const [searchTerm, setSearchTerm] = useState("");

	// Compute categories that have at least one insurance page
	const categoriesWithInsurances = useMemo(() => {
		const insurancePagesByCategory = data.allWpInsurancePage.nodes.reduce(
			(acc, page) => {
				const categoryId = page.insurancePageFields.insuranceType.id;
				if (!acc[categoryId]) {
					acc[categoryId] = [];
				}
				acc[categoryId].push(page);
				return acc;
			},
			{}
		);

		return data.allWpInsuranceCategory.nodes.filter(
			(category) => insurancePagesByCategory[category.id]?.length > 0
		);
	}, [data.allWpInsuranceCategory.nodes, data.allWpInsurancePage.nodes]);

	const { filteredInsurancePages, groupedInsurances } = useMemo(() => {
		const filteredPages = data.allWpInsurancePage.nodes.filter((page) => {
			const matchesCategory =
				categoryFilter === "All" ||
				page.insurancePageFields.insuranceType.id === categoryFilter;
			const matchesSearchTerm =
				searchTerm === "" ||
				page.title.toLowerCase().includes(searchTerm.toLowerCase());
			return matchesCategory && matchesSearchTerm;
		});

		const grouped = filteredPages.reduce((acc, page) => {
			const typeId = page.insurancePageFields.insuranceType.id;
			if (!acc[typeId]) {
				acc[typeId] = {
					categoryName: page.insurancePageFields.insuranceType.title,
					pages: [],
				};
			}
			acc[typeId].pages.push(page);
			return acc;
		}, {});

		return {
			filteredInsurancePages: filteredPages,
			groupedInsurances: grouped,
		};
	}, [data.allWpInsurancePage.nodes, categoryFilter, searchTerm]);

	const resetFilters = () => {
		setCategoryFilter("All");
		setSearchTerm("");
	};
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Insurance Categories",
				item: {
					url: `${siteUrl}/insurance-categories`,
					id: `${siteUrl}/insurance-categories`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={data.wpInsuranceCategoriesPage.seoFields.metaTitle}
				description={data.wpInsuranceCategoriesPage.seoFields.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/insurance-categories`,
					title: data.wpInsuranceCategoriesPage.seoFields.opengraphTitle,
					description:
						data.wpInsuranceCategoriesPage.seoFields.opengraphDescription,
					images: [
						{
							url: `${data.wpInsuranceCategoriesPage.seoFields.image?.link}`,
							width: `${data.wpInsuranceCategoriesPage.seoFields.image?.localFile.childImageSharp.original.width}`,
							height: `${data.wpInsuranceCategoriesPage.seoFields.image?.localFile.childImageSharp.original.height}`,
							alt: `${data.wpInsuranceCategoriesPage.seoFields.image?.altText}`,
						},
					],
				}}
			/>
			<div className="bg-med-grey pt-md-5 pb-md-7 insurance-categories">
				<Container>
					<Row className="justify-content-center py-5 py-lg-7">
						<Col className="text-md-center " lg={6}>
							{parse(
								data.wpInsuranceCategoriesPage.insuranceCategoriesPageFields
									.heroSection.title
							)}
							<p className="mt-4">
								{
									data.wpInsuranceCategoriesPage.insuranceCategoriesPageFields
										.heroSection.description
								}
							</p>
						</Col>
					</Row>
					<Row className="justify-content-center m-1 mb-md-5 mb-4 ">
						<Col lg={12}>
							<Row
								style={{
									...cardStyle,
									boxShadow: "0px 16px 19.8px 0px rgba(24, 122, 218, 0.12)",
								}}
							>
								<Col
									className=" "
									style={{
										...contentStyle,
										overflow: "hidden",
										backgroundColor: "#F9F9FB",
									}}
								>
									<Row className=" align-items-center justify-content-between">
										<Col md={7} xs={12}>
											<Row className="align-items-center  position-relative">
												<Col
													style={{
														background:
															"linear-gradient(138deg, #29B9F2 2.82%, #0433BF 97.42%)",
													}}
													className="py-4"
													xs={2}
												>
													<div className="text-center">
														<img
															src={filterIcon}
															alt="Filter"
															style={{
																width: "30px",
															}}
														/>
													</div>
												</Col>

												<Col
													className="custom-dropdown "
													xl={7}
													lg={6}
													md={8}
													xs={7}
												>
													<Form.Select
														aria-label="Filter by Category"
														style={{ backgroundColor: "#F9F9FB" }}
														value={categoryFilter}
														onChange={(e) => setCategoryFilter(e.target.value)}
													>
														<option value="All">Type</option>
														{categoriesWithInsurances.map((category) => (
															<option key={category.id} value={category.id}>
																{category.title}
															</option>
														))}
													</Form.Select>
												</Col>
												<Col xl={3} lg={4} md={2} xs={3}>
													<div
														className="h-100 position-absolute top-0"
														style={{ borderRight: "1px solid #D6D6D6" }}
													></div>
													<a
														className="ms-4 red-link d-flex align-items-center"
														style={{ cursor: "pointer" }}
														onClick={resetFilters}
													>
														<MdOutlineReplay className="fs-3  d-lg-none" />
														<MdOutlineReplay className="fs-5 me-lg-2 d-none d-lg-inline-block" />
														<span className="d-none d-lg-inline-block">
															Reset Filters
														</span>
													</a>
												</Col>
											</Row>
										</Col>
										<Col md={5} xs={12}>
											<Row className="justify-content-end align-items-center">
												<Col>
													<div
														style={{
															overflow: "hidden",
															border: "1px solid #D6D6D6",
														}}
														className="d-flex align-items-center rounded bg-white p-1 d-none d-sm-flex"
													>
														<FaSearch className="ms-3 text-schema-grey" />{" "}
														{/* FontAwesome icon */}
														<Form.Control
															type="search"
															className="border-0 ms-1"
															placeholder="Search "
															value={searchTerm}
															style={{
																outline: "none",
																boxShadow: "none",
															}}
															onChange={(e) => setSearchTerm(e.target.value)}
														/>
													</div>
												</Col>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>

					<div
						style={{
							...cardStyle,
							boxShadow: "0px 16px 19.8px 0px rgba(24, 122, 218, 0.12)",
						}}
						className="  mx-1 d-md-none"
					>
						<div
							className="d-flex align-items-center p-1"
							style={{
								...contentStyle,
								overflow: "hidden",
								backgroundColor: "white",
							}}
						>
							<FaSearch className="ms-3 text-schema-grey" />{" "}
							{/* FontAwesome icon */}
							<Form.Control
								type="search"
								className="border-0 ms-1"
								placeholder="Search "
								value={searchTerm}
								style={{
									outline: "none",
									boxShadow: "none",
								}}
								onChange={(e) => setSearchTerm(e.target.value)}
							/>
						</div>
					</div>

					{categoriesWithInsurances.map((category) => {
						const categoryData = groupedInsurances[category.id];
						if (categoryData?.pages.length) {
							return (
								<div className="my-6" key={category.id}>
									{categoryFilter === "All" && (
										<h2 className="text-dark-bg text-center mb-5">
											{category.title}
										</h2>
									)}

									<div className={`${categoryFilter === "All" ? "" : "mt-7"}`}>
										<Row className="g-4">
											{categoryData.pages.map((page) => (
												<Col md={6} lg={4}>
													<div
														key={page.slug}
														className="h-100"
														style={cardStyle}
													>
														<div
															className=" h-100 d-flex align-items-center justify-content-center"
															style={contentStyle}
														>
															<div className="d-block p-4">
																<h3 className="gradient-text ">{page.title}</h3>
																<p>
																	{page.insurancePageFields.cardDescription}
																</p>
																<Link
																	to={`/insurance/${page.slug}`}
																	style={linkGradientStyle}
																>
																	Read more <MdArrowForwardIos />
																</Link>
															</div>
														</div>
													</div>
												</Col>
											))}
										</Row>
									</div>
								</div>
							);
						}
						return null;
					})}
					{categoryFilter !== "All" && (
						<Row>
							<Col>
								<p className="text-center">
									Find the right insurance by getting a{" "}
									<a
										style={{ ...linkGradientStyle, cursor: "pointer" }}
										onClick={resetFilters}
									>
										View All
									</a>
								</p>
							</Col>
						</Row>
					)}
				</Container>
			</div>
			<NewsletterBanner
				title={
					data.wpInsuranceCategoriesPage.insuranceCategoriesPageFields
						.ctaBannerSection.title
				}
			/>
		</Layout>
	);
};

export default InsuranceCatPage;
