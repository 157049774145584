import React, { useState } from "react";
import {
	Container,
	Row,
	Col,
	InputGroup,
	FormControl,
	Button,
	Form,
} from "react-bootstrap";
import parse from "html-react-parser";
import { toast, ToastContainer } from "react-toastify"; // Import Toastify components
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS
import SafeHtmlParser from "./safe-html-parser";

const NewsletterBanner = ({ title }) => {
	const [email, setEmail] = useState("");
	const [validated, setValidated] = useState(false);
	const apiKey = process.env.GATSBY_MY_SECRET_API_KEY;

	const handleSubmit = async (event) => {
		const form = event.currentTarget;
		event.preventDefault(); // Prevent default form submission

		if (!form.checkValidity()) {
			event.stopPropagation();
		} else {
			// Assuming you have a Netlify function to handle newsletter subscriptions
			try {
				const response = await fetch(
					"/.netlify/functions/subscribeNewsletter",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							"x-api-key": apiKey,
						},
						body: JSON.stringify({ email }),
					}
				);

				if (response.ok) {
					toast.success("Subscription successful!");
					setEmail(""); // Clear the input on successful submission
				} else {
					throw new Error("Subscription failed.");
				}
			} catch (error) {
				toast.error("Subscription failed. Please try again.");
			}
		}

		setValidated(true); // Enable Bootstrap validation feedback
	};

	return (
		<section
			style={{ background: "linear-gradient(#001249, #000B2D)" }}
			className="py-5 py-lg-7"
		>
			<Container className="newsletter-subscribe-container">
				<Row className="justify-content-center align-items-center">
					<Col md={5} className="text-white">
						<SafeHtmlParser htmlContent={title} />
					</Col>
					<Col md={7}>
						<Form
							className="pb-3"
							noValidate
							validated={validated}
							onSubmit={handleSubmit}
						>
							<Row className="align-items-end">
								<Col xl={9} sm={6}>
									<Form.Group className="mt-3" controlId="email">
										<FormControl
											name="email"
											className="custom-input pb-3"
											placeholder="Enter your email"
											required
											type="email"
											onChange={(e) => setEmail(e.target.value)}
										/>
										<Form.Control.Feedback type="invalid">
											Please provide a valid email.
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
								<Col xl={3} sm={6}>
									<Button
										type="submit"
										variant="white"
										className="Jakarta-Bold bg-white black-link w-100 px-4 py-3  mt-4 mt-md-3"
									>
										SUBSCRIBE
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
			</Container>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</section>
	);
};

export default NewsletterBanner;
